<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex md12>
        <v-card elevation="1" height="400" width="700">
          <h1 class="text-justify .display-2 ml-4 pl-4 pt-4">
            Mudar senha
          </h1>
          <p class="ml-4 pl-4">Insira as novas senhas desejadas</p>
          <v-form ref="form" v-model="valid" :lazy-validation="lazy">
            <v-text-field v-model="email" label="Email" required class="ml-4 pl-4 mr-4 pr-5"></v-text-field>
            <v-text-field v-model="password" type="password" label="Senha" required class="ml-4 pl-4 mr-4 pr-5"></v-text-field>
            <v-text-field v-model="passwordrepeat" type="password" label="Confirmar Senha" required class="ml-4 pl-4 mr-4 pr-5"></v-text-field>
            <v-btn color="primary" class="ml-5 pl-6 mr-4 pr-5" @click="atualizar()">Atualizar</v-btn>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
      

      <v-snackbar
        color="#000"
        :bottom="bottom"
        :top="top"
        :left="left"
        :right="right"
        v-model="snackbar"
        dark
      >
        <v-icon color="#fff" class="mr-3">mdi-bell-plus</v-icon>
        <div>{{snackbarMessage}}</div>
        <v-icon size="16" @click="snackbar = false">mdi-close-circle</v-icon>
      </v-snackbar>

      <v-snackbar
        color="#BA0000"
        :bottom="bottom"
        :top="top"
        :left="left"
        :right="right"
        v-model="snackbarerror"
        dark
      >
        <v-icon color="#fff" class="mr-3">mdi-bell-plus</v-icon>
        <div>
          {{snackbarMessageError}}
        </div>
        <v-icon size="16" @click="snackbarerror = false">mdi-close-circle</v-icon>
      </v-snackbar>

  </v-container>
</template>

<script>
export default {
  data: () => ({
    email: '',
    passwordrepeat: '',
    password: '',
    snackbar: false,
    snackbarMessage: "Atualizado",
    snackbarerror : false,
    snackbarMessageError: "Não foi possível atualizar a senha",
  }),
  methods: {
    atualizar(){
      // console.log(this.$route);
      
      let token = this.$route.params.token;
      if(this.email && this.password && this.passwordrepeat && token){
        if(this.password && this.passwordrepeat){
          this.$http.post('/reset-password', {
            email: this.email, token: token, password: this.password
          }).then((res) => {
            this.snackbar = true;
            this.snackbarMessage = res.data.message;
          }).catch((err) => {
            this.snackbarerror=true;
            this.snackbarMessageError = err.error;
          })
        }
      }
    },
    verifyKey(){
      let {token} = this.$route.params;
      this.$http.get(`/validar-token/${token}`).then(res => {return true}).catch((err) => {
        
        return false
        });
    }
  },
};
</script>
